import {
    Abstract
} from './Abstract';

export class SalesMan extends Abstract {
    constructor(session) {
        super('comercial/SalesMan', session);
        this.id_user = null;
        this.name = null;
        this.last_name = null;
        this.surname = null;
        this.phone = null;
        this.maximum_discount = 0.00;
        this.comision_percentage = 0.00;
        this.rfc = null;
        this.bank_reference = null;
        this.interbank_code = null;
        this.external_reference = null;
        this.active = null;
        
    }
}