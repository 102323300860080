<template>
  <div class="col-12">
    <div class="card">
      <Loader v-model="loading"/>
      <DeleteDialog @closed="deleteDialog = false" v-model="deleteDialog" @deleted="deleteEntity" /> 
      <HelperDialog v-model="userHelper" header="Buscar Usuario" :headers="userHeaders" :rows="users" @selected="selectUser" />
      <Panel header="Vendedor">
        <BasicFormToolbar @new="openNew" @save="save" @list="list" :actions="['new', 'save', 'list']"/>
        <Panel header="Datos generales">
          <div class="p-fluid formgrid grid">
            <FormInputText  wrapperClass="field col-4" label="Nombre(s)" v-model="entity.name" :valid="validate.validations.name"/>
            <FormInputText  wrapperClass="field col-4" label="Apellido Paterno" v-model="entity.last_name" :valid="validate.validations.last_name" />
            <FormInputText wrapperClass="field col-4" label="Apellido Materno" v-model="entity.surname" :valid="validate.validations.surname"/>
            <FormInputText  wrapperClass="field col-2" label="Teléfono" v-model="entity.phone" :valid="validate.validations.phone"/>
            <FormInputNumber  wrapperClass="field col-2" label="Descuento máximo" v-model="entity.maximum_discount" prefix="%" :valid="validate.validations.maximum_discount"/>
            <FormInputNumber  wrapperClass="field col-2" label="Porcentaje Comisión" v-model="entity.commission_percentage" prefix="%" :valid="validate.validations.commission_percentage"/>
            <FormInputText  wrapperClass="field col-2" label="RFC" v-model="entity.rfc" :valid="validate.validations.rfc"/>
            <FormInputText  wrapperClass="field col-4" label="Referencia Bancaria" v-model="entity.bank_reference" :valid="validate.validations.bank_reference"/>
            <FormInputText  wrapperClass="field col-4" label="Cuenta Clabe" v-model="entity.interbank_code" :valid="validate.validations.interbank_code"/>
            <FormInputText  wrapperClass="field col-4" label="Referencia externa" v-model="entity.external_reference" :valid="validate.validations.external_reference"/>
            <FormInputText  wrapperClass="field col-2" label="Usuario" @search="(userHelper.visible = true)" :search="true" v-model="entity.id_user" :valid="validate.validations.id_user"/>
            <FormInputText  wrapperClass="field col-2" label="Nombre Usuario" v-model="entity.username"/>
          </div>
        </Panel>
        <br>
        <Panel header="Registros">
          <BasicDatatable @edited="edit" @deleted="confirmDelete" :headers="headers" :rows="entities" :rowaction="true" :rowdelete="true" :rowedit="true" />  
        </Panel>

      </Panel>
    </div>
  </div>
</template>


<script>

import Session from "../../../mixins/sessionMixin";
import { SalesMan } from "../../../models/comercial/SalesMan";
import {HeaderGrid,Rule,Toast,ErrorToast,fillObject,validate} from "../../../utilities/General";


import BasicFormToolbar from '../../../components/general/BasicFormToolbar.vue'
import FormInputText from '../../../components/general/FormInputText.vue'
import FormInputNumber from '../../../components/general/FormInputNumber.vue'
import Loader from "../../../components/general/Loader.vue"
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import HelperDialog from '../../../components/general/HelperDialog.vue';
import DeleteDialog from '../../../components/general/DeleteDialog.vue'
import { ERPUser} from "../../../models/seguridad/ERPUser";

export default {
  mixins: [Session],
  data() {
    return {
      entity: null,
      entities:[],
      users:[],
      loading: false,
      deleteDialog: false,
      userHelper:{
        visible: false,
      },
      
      rules: [
        new Rule({ name: "id_user" }),
        new Rule({ name: "name" }),
        new Rule({ name: "last_name" }),
        new Rule({ name: "surname" }),
        new Rule({ name: "phone" }),
        new Rule({ name: "bank_reference" }),
        new Rule({ name: "interbank_code" }),
        new Rule({ name: "external_reference" }),
        new Rule({ name: "rfc" }),
      ],
      headers: [
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("Apellido Paterno", "last_name"),
        new HeaderGrid("Apellido Materno", "surname"),
        new HeaderGrid("Usuario", "username"),
      ],
      userHeaders:[
        new HeaderGrid("Folio usuario", "id"),
        new HeaderGrid("Nombre usuario", "username"),
        new HeaderGrid("Nombre", "name"),
        new HeaderGrid("Apellidos", "lastname"),
      ],
      validate: {
        valid: false,
        validations: {
          id_user: null,
          name: null,
          last_name: null,
          surname: null,
          phone: null,
          bank_reference: null,
          interbank_code: null,
          external_reference: null,
          rfc: null
        },
      },
    }
  },
  components: {BasicFormToolbar,FormInputNumber,FormInputText,Loader,HelperDialog,BasicDatatable,DeleteDialog},
  async mounted()
    {
      await this.refresh();
    },
    async created() {
    this.entity = new SalesMan(this.session); 
  },
  methods: {
   
    openNew() {
        this.entity = new SalesMan(this.session);
    },
    selectUser(payload){
      this.entity.id_user = payload.id;
      this.entity.username = payload.username
    },

    edit(entity) {
      this.entity = fillObject(this.entity, entity);
    },

    confirmDelete(entity) {
      this.entity = fillObject(this.entity, entity);
      this.deleteDialog = true;
    },

    async deleteEntity() {
      try {
        this.loading = true;
        //* Eliminamos de la base
        await this.entity.delete();
        //* Eliminamos de la vista
        this.entities = this.entities.filter((val) => val.id !== this.entity.id);
        this.deleteDialog = false;
        //* Limpiamos la entidad
        this.entity = new SalesMan(this.session);
        this.$toast.add({
          severity: "success",
          summary: "Eliminacion",
          detail: "Registro eliminado con exito",
          life: 3000,
        });
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },

    async refresh() {
      this.loading = true;
      try { 
        if(!this.modal)
        //this.entities = await this.entity.all();
        this.users = await new ERPUser(this.session).all();
        if (this.id) {
          this.entity.id = this.id;
          let entity = await this.entity.retrieve();
          this.entity = fillObject(this.entity, entity);
        }
        } catch (error) {
              this.$toast.add(new ErrorToast(error));
            } finally {
              this.loading = false;
            }
      },

      async save() {
            try {
              console.log("Session: " +JSON.stringify(this.session))
                //* Validacion de form
                this.loading = true;
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid) {
                    throw "Favor de validar los campos";
                }
                //* Si el id es != 0 entonces actualizamos, si no, guardamos
                if (this.entity.id && this.entity.id > 0) {
                    //* Actualizamos
                    let entity = await this.entity.update();
                    this.$toast.add({
                        severity: "success",
                        summary: "Actualizar",
                        detail: "Informacion actualizada con exito",
                        life: 3000,
                    });
                    this.$emit('update', entity);
                    //* Modificamos el listado pah
                    let index = this.entities.findIndex((x) => x.id == this.entity.id);
                    this.entities[index] = entity;
                } else {
                    let entity = await this.entity.save();
                    //* Agregamos un dato extra
                    this.$emit('save', entity);
                    this.entities.push(entity);
                    this.$toast.add(
                        new Toast({
                            summary: "Creacion",
                            detail: "Informacion guardada con exito",
                        })
                    );
                }
                this.entity = new SalesMan(this.session);
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },

        async list() {
        this.loading = true;
        try {
          this.entities = await this.entity.data({
            id_user: this.entity.id_user,
            lastname: this.entity.last_name,
            surname: this.entity.surname,
            name: this.entity.name
          });
        } catch (error) {
          this.$toast.add(new ErrorToast(error));
        } finally {
          this.loading = false;
        }
      },
  },
}
</script>

<style>

</style>